import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  FaPlay,
  FaPause,
  FaArrowRight,
  FaArrowLeft,
  FaVolumeUp,
  FaExpand,
  FaCompress,
  FaDownload,
  FaVideo,
  FaImage,
  FaSlideshare,
  FaRocket,
  FaExternalLinkAlt,
  FaFileWord,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Download from "./components/Download";
import GuideShare from "./components/GuideShare";
import GuideHTML from "./components/GuideHTML";

const PreviewGuide = ({ guideData, defaultTab = 0, bustCache = false }) => {
  const [landscapeShareUrl, setLandscapeShareUrl] = useState("");

  // console.log(guideData);
  const getDefaultTabIndex = () => {
    if (defaultTab && guideData.media && guideData.media[defaultTab]) {
      // Assuming the order of tabs is guide, video, gif, slide
      const mediaKeys = [
        "guide",
        "video",
        "gif",
        "slide",
        "portraitVideo",
        "HTML",
        "doc",
        "gifPortrait",
      ]; // Static order of tabs
      return mediaKeys.indexOf(defaultTab);
    }
    return 0; // Fallback to the first tab if conditions aren't met
  };

  const [isGuideDataValid, setIsGuideDataValid] = useState(false);
  const [showHTML, setShowHTML] = useState(false);

  // This effect checks for the validity of guideData.steps and their stepTitle

  useEffect(() => {
    const baseURL = "https://www.kimavi.com"; // Hardcoded as per your request
    if (guideData.settings && guideData.settings.public) {
      const newLandscapeShareUrl = `${baseURL}/video?id=${guideData.recordingSessionId}&source=server&uid=${guideData.uid}`;
      setLandscapeShareUrl(newLandscapeShareUrl);
    } else {
      setLandscapeShareUrl(""); // Or set it to some default or placeholder text
    }
  }, [guideData]); // Re-run this effect if guideData changes

  useEffect(() => {
    // This effect runs whenever guideData changes.
    // It checks if guideData and its steps are valid.
    // If so, it resets both selectedIndex and currentIndex.
    const isDataValid = guideData?.steps?.every((step) => step?.stepTitle);

    if (isDataValid) {
      setIsGuideDataValid(true);
      setSelectedIndex(getDefaultTabIndex()); // Reset tab index based on defaultTab or guideData changes
      setCurrentIndex(0); // Reset step index to the start
    } else {
      setIsGuideDataValid(false); // If the data is not valid, update state accordingly.
    }
  }, [guideData, defaultTab]); // Dependencies: guideData and defaultTab

  useEffect(() => {
    setSelectedIndex(getDefaultTabIndex()); // Reset tab index based on defaultTab or guideData changes
    setCurrentIndex(0); // Reset step index to start from the first step
  }, [guideData, defaultTab]); // Listen to changes in guideData and defaultTab

  const sanitizeFilename = (title) => {
    return title
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^a-zA-Z0-9-]/g, "") // Remove all non-alphanumeric characters except -
      .toLowerCase(); // Optional: Convert to lower case
  };

  const mediaButtons = [
    guideData.media?.video && {
      label: "Download Video",
      href: guideData.media.video,
      downloadName: `${sanitizeFilename(guideData.guideTitle)}.mp4`,
      Icon: FaVideo,
    },
    guideData.media?.gif && {
      label: "Download GIF",
      href: guideData.media.gif,
      downloadName: `${sanitizeFilename(guideData.guideTitle)}.gif`,
      Icon: FaImage,
    },
    guideData.media?.slide && {
      label: "Google Slides",
      href: guideData.media.slide,
      action: () => window.open(guideData.media.slide, "_blank"),
      Icon: FaSlideshare,
    },
    guideData.media?.doc && {
      label: "Google Docs",
      href: guideData.media.doc,
      action: () => window.open(guideData.media.doc, "_blank"),
      Icon: FaFileWord, // This represents your Google Docs icon
    },
  ].filter(Boolean); // Remove falsey values (e.g., if a media type doesn't exist)

  const getDefaultTabIndex1 = () => {
    if (defaultTab && guideData.media) {
      // console.log("guideData.media", guideData.media);
      const mediaKeys = ["guide", ...Object.keys(guideData.media)];
      // console.log("mediaKeys", mediaKeys);
      let index = mediaKeys.indexOf(defaultTab);
      // console.log("index", index);
      return index >= 0 ? index : 0;
    }
    return 0; // Return 0 if conditions not met
  };

  const [selectedIndex, setSelectedIndex] = useState(getDefaultTabIndex());

  // Listen for changes in defaultTab prop and update state
  useEffect(() => {
    setSelectedIndex(getDefaultTabIndex());
  }, [defaultTab, guideData.media]);
  // Check which media types are available and adjust the default tab index as needed

  // Other existing states and refs

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const intervalRef = useRef(null);
  const audioRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(new Image());
  const blinkIntervalRef = useRef(null);
  const [isSoundOn, setIsSoundOn] = useState(false); // Manage sound state

  // **Reintroduce Missing State Variables**
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const [scale, setScale] = useState(0.7); // State to manage the canvas scale

  // Logic to determine title and description
  const title = guideData.guideTitle ?? "How to Guides by Kimavi";
  const location = useLocation();
  const isNotRoot = location.pathname !== "/";

  useEffect(() => {
    // console.log("101", guideData);
    const audioUrl = guideData?.steps[currentIndex]?.audioUrl;
    // Only initialize or update the Audio object if the URL is valid
    if (audioUrl) {
      if (bustCache) {
        const cacheBustedUrl = `${audioUrl}?cacheBuster=${Date.now()}`;
      }
      audioRef.current = new Audio(audioUrl);
      // Additional setup if needed, like adding event listeners
    } else {
      // Handle the case where audioUrl is not valid (e.g., set to null or show an error)
      audioRef.current = null; // Ensures we don't try to use an invalid Audio object
    }
  }, [guideData, currentIndex]); // Re-run this effect if guideData or currentIndex changes

  const description =
    guideData.steps?.[0]?.customAudioText &&
    guideData.steps[0].customAudioText.trim() !== ""
      ? guideData.steps[0].customAudioText
      : guideData.steps?.[0]?.stepTitle ??
        "Explore expert guides and tutorials on Kimavi.";

  const toggleScale = () => {
    setScale(scale === 0.7 ? 1.0 : 0.7);
  };
  // Function to increase scale to 0.8
  const increaseScale = () => {
    if (scale === 0.7) {
      setScale(1.0);
    }
  };

  // Preload assets ahead of time in slideshow mode
  useEffect(() => {
    if (selectedIndex === 0) {
      const preloadCount = 3; // Number of slides ahead to preload
      for (let i = currentIndex + 1; i <= currentIndex + preloadCount; i++) {
        const index = i % guideData.steps.length; // Ensure index wraps around
        const step = guideData.steps[index];
        // Preload image
        if (step?.screenshotUrl) {
          const img = new Image();
          img.src = step.screenshotUrl;
        }
        // Preload audio
        if (step?.audioUrl) {
          const audio = new Audio();
          audio.src = step.audioUrl;
        }
      }
    }
  }, [currentIndex, selectedIndex, guideData.steps]);

  useEffect(() => {
    const adjustCanvasSize = () => {
      const { innerWidth, innerHeight } = window;
      const maxWidth = innerWidth;
      const maxHeight = innerHeight * 0.8;

      setDimension({ width: maxWidth, height: maxHeight });
    };

    adjustCanvasSize();
    window.addEventListener("resize", adjustCanvasSize);

    return () => window.removeEventListener("resize", adjustCanvasSize);
  }, []);

  useEffect(() => {
    // Setup audio element for the current slide
    audioRef.current = new Audio(guideData.steps[currentIndex]?.audioUrl || "");
  }, [currentIndex, guideData.steps]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % guideData.steps.length);
  };

  const toggleSound = () => {
    setIsSoundOn(!isSoundOn);
  };

  useEffect(() => {
    // Effect for audio playback
    const playAudio = () => {
      if (guideData.steps[currentIndex]?.audioUrl && isSoundOn) {
        audioRef.current
          .play()
          .catch((e) => console.error("Audio playback failed", e));
      }
    };

    playAudio();

    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
  }, [currentIndex, isSoundOn, guideData.steps]);

  const startSlideshow = () => {
    if (!isPlaying || selectedIndex !== 0) return;

    if (isSoundOn && guideData.steps[currentIndex]?.audioUrl) {
      // Clear any existing intervals
      clearInterval(intervalRef.current);
      audioRef.current.onended = () => {
        handleNext();
      };
    } else {
      // Setup regular interval if sound is not enabled or no audio URL is present
      intervalRef.current = setInterval(() => {
        handleNext();
      }, 4000);
    }
  };

  useEffect(() => {
    // This effect starts or stops the slideshow based on isPlaying state
    if (selectedIndex === 0) {
      startSlideshow();

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isPlaying, isSoundOn, currentIndex, selectedIndex]);

  // Clean up on component unmount
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = ""; // Clear the source
      }
    };
  }, []);

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      if (blinkIntervalRef.current) {
        clearInterval(blinkIntervalRef.current);
        blinkIntervalRef.current = null;
      }
    };
  }, []);

  const drawDot = (ctx, x, y, scaleRatio) => {
    const adjustedClickX = x;
    const adjustedClickY = y;

    // Check if x and y are zero; if so, do not draw the dot
    if (!x || !y || (x === 0 && y === 0)) {
      // Clear any existing intervals
      if (blinkIntervalRef.current) {
        clearInterval(blinkIntervalRef.current);
        blinkIntervalRef.current = null;
      }
      return;
    }

    clearInterval(blinkIntervalRef.current);
    let dotStage = 0;

    const draw = () => {
      if (!canvasRef.current) return;
      ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height); // Clear the canvas
      ctx.drawImage(
        imageRef.current,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      ); // Redraw the image

      // Draw the dot based on the current stage
      if (dotStage >= 1) {
        ctx.fillStyle = "rgba(255, 0, 0, 0.5)";
        ctx.beginPath();
        ctx.arc(
          adjustedClickX,
          adjustedClickY,
          30 * scaleRatio,
          0,
          2 * Math.PI
        );
        ctx.fill();
      }
      if (dotStage >= 2) {
        ctx.fillStyle = "rgba(255, 255, 0, 0.5)";
        ctx.beginPath();
        ctx.arc(
          adjustedClickX,
          adjustedClickY,
          20 * scaleRatio,
          0,
          2 * Math.PI
        );
        ctx.fill();
      }
      if (dotStage === 3) {
        ctx.fillStyle = "rgba(255, 0, 0, 0.3)";
        ctx.beginPath();
        ctx.arc(
          adjustedClickX,
          adjustedClickY,
          10 * scaleRatio,
          0,
          2 * Math.PI
        );
        ctx.fill();
      }
      dotStage = (dotStage + 1) % 4; // Cycle through stages 0-3
    };

    blinkIntervalRef.current = setInterval(draw, 500); // Adjust the blinking speed if needed
  };

  // Draw the canvas and handle resizing
  useEffect(() => {
    if (selectedIndex !== 0) return; // Only draw on the first tab
    const updateCanvasAndImage = () => {
      const canvas = canvasRef.current;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");
      const selectedStep = guideData.steps[currentIndex];

      imageRef.current.onload = () => {
        // Get the container's width
        const containerWidth = canvas.parentElement.clientWidth;

        // Calculate image aspect ratio
        const imgWidth = imageRef.current.naturalWidth;
        const imgHeight = imageRef.current.naturalHeight;
        const imgAspectRatio = imgWidth / imgHeight;

        // Set canvas size to match container width and maintain aspect ratio
        const canvasWidth = containerWidth * scale;
        const canvasHeight = canvasWidth / imgAspectRatio;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Draw the image onto the canvas
        ctx.drawImage(imageRef.current, 0, 0, canvasWidth, canvasHeight);

        // Scale click positions
        const scaleX = canvasWidth / imgWidth;
        const scaleY = canvasHeight / imgHeight;
        const adjustedClickX = selectedStep.clickX * scaleX;
        const adjustedClickY = selectedStep.clickY * scaleY;

        drawDot(ctx, adjustedClickX, adjustedClickY, Math.min(scaleX, scaleY));
      };

      // Set image source each time currentIndex changes
      imageRef.current.src = selectedStep?.screenshotUrl ?? "";
    };

    window.addEventListener("resize", updateCanvasAndImage);
    updateCanvasAndImage();

    return () => {
      window.removeEventListener("resize", updateCanvasAndImage);
      if (blinkIntervalRef.current) {
        clearInterval(blinkIntervalRef.current);
        blinkIntervalRef.current = null;
      }
    };
  }, [currentIndex, guideData.steps, selectedIndex, scale]);

  const extractedDocIdFromURL = (url) => {
    const urlParams = new URL(url).searchParams;
    return urlParams.get("id");
  };

  if (!isGuideDataValid) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {isGuideDataValid ? (
        // Render your component if guideData.steps is valid
        <div className="flex justify-center items-center w-full">
          <div className="w-full p-5 bg-white shadow-lg rounded-lg">
            {isNotRoot && (
              <Helmet>
                <title>{title}</title>
                <meta
                  name="description"
                  content={description || "Default description"}
                />
              </Helmet>
            )}
            <div className="flex flex-col p-5 bg-gray-100">
              <p>Guide Title</p>
              <div className="text-lg md:text-3xl text-center text-teal-400 font-bold mb-2 bg-black m-3 p-3 rounded mb-2 mt-2">
                {guideData.guideTitle}
              </div>

              <div className="flex flex-wrap gap-2 justify-center text-center">
                <>
                  {guideData.tags &&
                    guideData.tags.length > 0 && // Filtering out blank tags
                    guideData.tags
                      .filter((tag) => tag.trim() !== "") // Ensuring we're only dealing with non-blank tags
                      .map((tag, index) => (
                        <div
                          key={index}
                          className="group flex items-center gap-2 bg-gray-200 p-2 mt-2 mb-2 rounded-full relative"
                        >
                          <span
                            className={`text-sm ${
                              index === 0 ? "text-gray-500" : "text-gray-700"
                            }`}
                          >
                            #{tag === "localhost" ? "kimavi" : tag}{" "}
                            {/* Modification here */}
                          </span>
                        </div>
                      ))}
                </>
              </div>
              <div className="flex w-full h-full p-5">
                <Tabs
                  className="w-full"
                  selectedIndex={selectedIndex}
                  onSelect={(index) => setSelectedIndex(index)}
                >
                  <TabList>
                    <Tab>Guide</Tab>
                    {guideData.media?.video && <Tab>Video</Tab>}
                    {guideData.media?.gif && <Tab>GIF</Tab>}
                    {guideData.media?.slide && <Tab>Slide</Tab>}

                    {guideData.media?.portraitVideo && (
                      <Tab>Portrait Video</Tab>
                    )}
                    {showHTML && <Tab>HTML</Tab>}

                    {guideData.media?.doc && <Tab>Doc</Tab>}
                    {guideData.mediaPortrait?.gifPortrait640 && (
                      <Tab>Portrait Gif</Tab>
                    )}

                    {guideData.media && <Tab>Download</Tab>}
                    {guideData.settings &&
                      guideData.settings.public &&
                      guideData.uid && <Tab>Share</Tab>}
                  </TabList>

                  <TabPanel>
                    <div className="bg-black m-2 p-2 mb-2 mt-2">
                      <div className="text-lg md:text-3xl text-center text-teal-400 mb-4 mt-4">
                        {currentIndex + 1}:{" "}
                        {guideData.steps[currentIndex]?.stepTitle}
                      </div>
                      <div className="flex justify-center mb-10 mt-10">
                        <div className="w-full max-w-screen-md">
                          <canvas
                            ref={canvasRef}
                            className="rounded overflow-hidden shadow-lg w-full h-auto"
                          ></canvas>
                        </div>
                      </div>
                    </div>

                    <div className="mb-5 mt-5 flex space-x-2">
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() =>
                          setCurrentIndex(
                            (currentIndex - 1 + guideData.steps.length) %
                              guideData.steps.length
                          )
                        }
                      >
                        <FaArrowLeft />
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        onClick={handleNext}
                      >
                        <FaArrowRight />
                      </button>
                      {/* Conditionally render the sound toggle button only if audioUrl exists for the current step */}
                      {guideData.steps[currentIndex]?.audioUrl && (
                        <button
                          className={`py-2 px-4 rounded font-bold ${
                            isSoundOn
                              ? "bg-green-500 hover:bg-green-700"
                              : "bg-gray-400"
                          } text-white flex items-center`}
                          onClick={toggleSound}
                        >
                          <FaVolumeUp />
                        </button>
                      )}
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
                        onClick={() => {
                          setIsPlaying(!isPlaying);
                          if (isPlaying) {
                            // setIsSoundOn(false); // Turn off sound when pausing the slideshow
                            audioRef.current.pause(); // Ensure to pause the audio playback
                          }
                        }}
                      >
                        {isPlaying ? <FaPause /> : <FaPlay />}
                      </button>
                      <button
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
                        onClick={toggleScale} // Use the toggleScale function here
                      >
                        {scale === 1.0 ? <FaCompress /> : <FaExpand />}
                      </button>
                      {guideData.steps[currentIndex]?.pageURL && (
                        <a
                          href={guideData.steps[currentIndex]?.pageURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                        >
                          <FaRocket />
                        </a>
                      )}
                    </div>
                    {/* Media Download Section */}
                  </TabPanel>

                  {guideData.media?.video && (
                    <TabPanel>
                      <div className="w-full max-w-screen-md mx-auto">
                        <video
                          controls
                          src={guideData.media.video}
                          className="w-full h-auto"
                        ></video>
                      </div>
                    </TabPanel>
                  )}

                  {guideData.media?.gif && (
                    <TabPanel>
                      <img
                        src={guideData.media.gif}
                        className="w-full h-auto"
                        alt="GIF"
                      />
                    </TabPanel>
                  )}

                  {guideData.media?.slide && (
                    <TabPanel>
                      <div className="w-full max-w-screen-md mx-auto">
                        <iframe
                          src={guideData.media.slide}
                          title={guideData.guideTitle}
                          className="w-full h-96"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </TabPanel>
                  )}

                  {guideData.media?.portraitVideo && (
                    <TabPanel>
                      <div className="mx-auto w-full max-w-md">
                        <video
                          controls
                          src={guideData.media.portraitVideo}
                          className="w-full h-auto object-cover"
                        ></video>
                      </div>
                    </TabPanel>
                  )}

                  {showHTML && (
                    <TabPanel>
                      <GuideHTML guideData={guideData} />
                    </TabPanel>
                  )}
                  {guideData.media?.doc && (
                    <TabPanel>
                      <div className="w-full max-w-screen-md mx-auto">
                        <iframe
                          src={`https://docs.google.com/document/d/${extractedDocIdFromURL(
                            guideData.media.doc
                          )}/preview`}
                          title={guideData.guideTitle}
                          className="w-full h-96"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </TabPanel>
                  )}

                  {guideData.mediaPortrait?.gifPortrait640 && (
                    <TabPanel>
                      <img
                        src={guideData.mediaPortrait.gifPortrait640}
                        className="w-full h-auto"
                        alt="Portrait Gif"
                      />
                    </TabPanel>
                  )}
                  {guideData.media && (
                    <TabPanel>
                      {" "}
                      {/* New TabPanel for Download */}
                      <Download guideData={guideData} />{" "}
                      {/* Render the Download component here */}
                    </TabPanel>
                  )}
                  {guideData.settings &&
                    guideData.settings.public &&
                    guideData.uid && (
                      <TabPanel>
                        <GuideShare guideData={guideData} />
                        <div className="flex flex-col items-center justify-center space-y-4 p-4">
                          <h3 className="text-lg font-semibold text-gray-700">
                            Share This Guide
                          </h3>
                          <input
                            type="text"
                            readOnly
                            value={landscapeShareUrl}
                            className="w-full px-4 py-2 text-gray-700 bg-gray-200 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onClick={(e) => e.target.select()}
                          />
                          <button
                            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(landscapeShareUrl)
                                .then(() => {
                                  // This will be executed if the text was successfully copied
                                  toast.success("Copied to clipboard!");
                                })
                                .catch(() => {
                                  // This will be executed if the copy operation failed
                                  toast.error("Failed to copy!");
                                });
                            }}
                          >
                            Copy Link
                          </button>
                        </div>
                      </TabPanel>
                    )}
                </Tabs>
              </div>
              <div className="flex justify-center items-center gap-4 p-4">
                {selectedIndex === 0 &&
                  guideData.steps[currentIndex]?.pageURL && (
                    <a
                      href={guideData.steps[currentIndex]?.pageURL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex flex-col items-center justify-center bg-gray-200 hover:bg-gray-300 text-gray-800 p-2 rounded-lg cursor-pointer transition"
                    >
                      <FaExternalLinkAlt className="mb-2 text-2xl" />
                      <span className="text-sm">View Step</span>
                    </a>
                  )}
                {mediaButtons.map(
                  ({ label, href, downloadName, action, Icon }, index) => (
                    <a
                      key={index}
                      href={href}
                      onClick={(e) => {
                        if (action) {
                          e.preventDefault(); // Prevent default action if there is a specific action to perform
                          action();
                        }
                      }}
                      target={!action ? "_blank" : undefined} // Only add target="_blank" if there's no specific action
                      rel={!action ? "noopener noreferrer" : undefined} // Add rel="noopener noreferrer" for external links
                      download={downloadName}
                      className="flex flex-col items-center justify-center bg-gray-200 hover:bg-gray-300 text-gray-800 p-2 rounded-lg cursor-pointer transition"
                    >
                      <Icon className="mb-2 text-2xl" />
                      <span className="text-sm">{label}</span>
                    </a>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        // Optional: Render a placeholder or nothing while waiting for valid guideData
        <div>Loading...</div>
      )}
    </>
  );
};
export default PreviewGuide;
